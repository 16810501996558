import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/PatientPage';

export const patientPageData = () => {
  const data = useStaticQuery(graphql`
    query patientPageQuery {
      dGraphQl {
        nodeQuery(filter: {conditions: [{field: "type", value: "patient_page"}]}) {
          entities {
            ... on DrupalGraphQl_NodePatientPage {
              path {
                alias
              }
              uuid
              entityLabel
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
              fieldContent {
                entity {
                  ...DrupalGraphQlParagraphContent
                }
              }
              fieldSection {
                entity {
                  ... on DrupalGraphQl_ParagraphSection {
                    fieldSubmenuName
                    fieldAnchorId
                    fieldLayout {
                      entity {
                        ...DrupalGraphQlParagraphContent
                        ...DrupalGraphQlParagraphTitle
                        ...DrupalGraphQlParagraphBackgroundSection
                        ...DrupalGraphQlParagraphButtonLink
                        ...DrupalGraphQlParagraphDownload
                        ...DrupalGraphQlParagraphImage
                        ...DrupalGraphQlParagraphSymptomsPatients
                        ...DrupalGraphQlParagraphTextLeftImageRight
                        ...DrupalGraphQlParagraphWarningText
                        ...DrupalGraphQlParagraphTable
                        ...DrupalGraphQlParagraphPublication
                        ...DrupalGraphQlParagraphMultiLines
                        ...DrupalGraphQlParagraphEvent
                        ...DrupalGraphQlParagraphSocialMedia
                        ...DrupalGraphQlParagraphImageLeftTextRight
                        ...DrupalGraphQlParagraphImageAnd4Descriptions
                      }
                    }
                    uuid
                  }
                }
              }
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                  }
                }
              }
              fieldMetaTitle
            }
          }
        }
      }
    }
  `);

  return transformers.patientPageTransform(data.dGraphQl);
}


